import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import chartExample from './chart.png'; // Make sure you have the chart example image in the specified path
import logo from './chartpedia.png'; // Make sure you have the Chartpedia logo image in the specified path
import './App.css';

function LunarDistanceBoxChartDocs() {
  return (
    <div className="App">
      <div className="App-container">
        <header className="App-header">
          <img src={logo} className="App-logo-corner" alt="Chartpedia logo" />
          <h1>Welcome to the Lunar Distance Box Chart Documentation!</h1>
          <p>
            The Lunar Distance Box Chart is a whimsical and imaginative visualization tool that combines the robustness of interval and metric data plotting with the mystical allure of astrological and tidal patterns. This chart dynamically integrates publicly available astrological data to uncover hidden patterns and correlations between the cosmos and your data.
          </p>
          <img src={chartExample} className="Chart-example" alt="Lunar Distance Box Chart example" />
          <h2>Chart Characteristics</h2>
          <ul>
            <li>Automatically plots interval and metric data against astrological data.</li>
            <li>Responsive to the phases of the moon, enriching data representation with tidal cycle confidence intervals.</li>
            <li>Utilizes non-linear curves to vividly represent tidal cycles.</li>
            <li>Automates data groupings based on lunar cycles and days of the week.</li>
            <li>Offers an optional feature to pivot data based on astrological signs for a more zodiac-centric analysis.</li>
          </ul>
          <h2>Getting Started</h2>
          <p>To attempt integrating the Lunar Distance Box Chart into your project, start by installing our package:</p>
          <code>npm install lunar-distance-box-chart</code>
          <h2>Example Usage</h2>
          <code>
            {`import { LunarDistanceBoxChart } from 'lunar-distance-box-chart';

<LunarDistanceBoxChart data={yourData} />`}
          </code>
        </header>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/lunar-distance-box-chart" element={<LunarDistanceBoxChartDocs/>} />
      </Routes>
    </Router>
  );
}

export default App;